import React from "react";

import {Link} from "gatsby";
import * as styles from "./series-title.module.css";
import {GatsbyImage} from "gatsby-plugin-image"

import BlockContent from "../block-content";

const {format} = require("date-fns");

const SeriesTitle = ({series, author}) => {
  const description = series && series._rawDescription;
  const publishedAt =
    series && series.publishedAt && format(new Date(series.publishedAt), "MMMM d, yyyy");
  return (
    <>
      <div className={styles.title}>{series.title}</div>
      <div className={styles.subtitle}>
        <GatsbyImage
          alt={author.author.image.alt}
          image={author.author.image.asset.gatsbyImageData}
          imgClassName={styles.image}/>
        <Link to="/about/" className={styles.name}>
          {author.author.name}
        </Link>
        <span>&#183;</span>
        <div className={styles.date}>{publishedAt}</div>
      </div>
      <div>
        {description && <BlockContent blocks={description || []}/>}
      </div>
    </>
  );
};

export default SeriesTitle;
