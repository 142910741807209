import React from "react";
import {graphql} from "gatsby";

import Container from "../components/container/container";
import GraphQLErrorList from "../components/graphql-error-list";
import Gallery from "../components/gallery/gallery";
import SEO from "../components/seo";
import Layout from "../containers/layout";
import SeriesTitle from "../components/series-title/series-title";
import {mapEdgesToNodes} from "../lib/helpers";

import * as styles from "./series.module.css";

export const query = graphql`
  query SeriesTemplateQuery($id: String!) {
    series: sanitySeries(id: {eq: $id}) {
      id
      publishedAt
      mainImage {
        asset {
          gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
        }
        alt
      }
      title
      slug {
        current
      }
      paintings {
        mainImage {
          asset {
            gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
          }
          alt
        }
        title
        slug {
          current
        }
        size
        medium {
          medium
        }
        publishedAt
        year
      }
      _rawDescription
      publishedAt
    }
    author: allSanitySiteSettings {
      edges {
        node {
          author {
            image {
              asset {
                gatsbyImageData(width: 40, height: 40, placeholder: BLURRED)
              }
              alt
            }
            name
          }
        }
      }
    }
  }
`;

const ProjectTemplate = props => {
  const {data, errors} = props;

  const series = data && data.series;
  const author = data && data.author && mapEdgesToNodes(data.author);

  return (
    <Layout>
      {errors && <SEO title="GraphQL Error"/>}
      {series && <SEO title={series.title || "Untitled"}/>}

      {errors && (
        <Container>
          <GraphQLErrorList errors={errors}/>
        </Container>
      )}
      <div className={styles.series}>
        <SeriesTitle series={series} author={author[0]}/>
      </div>
      {series && <Gallery paintings={series.paintings}/>}
    </Layout>
  );
};

export default ProjectTemplate;
